export function fetchScoutMatch(idMatch) { return { type: 'SCOUT/MATCH/FETCH', idMatch } }

export function fetchUser(idUser) { return { type: 'USER/FETCH', idUser } }
export function fetch_family() { return { type: 'MEMBERS/FAMILY/FETCH' } }
export function fetchUserEvaluations(idUser) { return { type: 'USER/EVALUATIONS/FETCH', idUser } }
export function fetchPenalties(IdCommunity) { return { type: 'PENALTIES/FETCH', IdCommunity } }

export function fetchCustomPlayer(idMatch) { return { type: 'CUSTOM_PLAYER/FETCH', idMatch } }
export function fetchCustomPlayerEvaluations(idMatch) { return { type: 'CUSTOM_PLAYER/EVALUATIONS/FETCH', idMatch } }

export function fetchRecruits({ SearchText, IdGender, Page, Filters }) { return { type: 'SCOUT/RECRUITS/FETCH', SearchText, IdGender, Page, Filters } }
export function fetchRecruitsBySeason({ SearchText, IdGender, Page, Filters, IdSeason }) { return { type: 'SCOUT/RECRUITS/SEASON/FETCH', SearchText, IdGender, Page, Filters, IdSeason } }
export function fetchCommunities() { return { type: 'COMMUNITIES/FETCH' } }
export function fetchCountries() { return { type: 'COUNTRIES/FETCH' } }
export function fetchFollowees() { return { type: 'FOLLOWEES/FETCH' } }
export function fetch_topics(idCommunity) { return { type: 'TOPICS/FETCH', idCommunity } }
export function fetchTeams() { return { type: 'FOLLOWEES/TEAMS/FETCH' } }
export function fecthTeam(idTeam) { return { type: 'TEAM/FETCH', idTeam } }
export function fetchFollowLevels() { return { type: 'FOLLOW_LEVELS/FETCH' } }
export function fetchFolders() { return { type: 'FOLDERS/FETCH' } }
export function fetchFolder(id) { return { type: 'FOLDER/FETCH', id } }
export function clearUser() { return { type: 'USER/CLEAR' } }
export function clearFolder() { return { type: 'FOLDER/CLEAR' } }
export function follow({ IdUserFollow }) { return { type: 'FOLLOW/USER', IdUserFollow } }
export function unfollow({ IdUserFollow }) { return { type: 'UNFOLLOW/USER', IdUserFollow } }

export function delete_match(IdMatch) { return { type: 'MATCH/DELETE', IdMatch } }
export function fetch_match(IdMatch) { return { type: 'GENERIC_MATCH/FETCH', IdMatch } }
export function clear_team() { return { type: 'TEAM/CLEAR' } }
export function save_match(match) { return { type: 'GENERIC_MATCH/SAVE', match } }

export function saveCustomPlayerReview(review) { return { type: 'CUSTOM_PLAYER/REVIEW/SAVE', review } }
export function follow_match(IdMatch) { return { type: 'FOLLOW/MATCH', IdMatch } }
export function follow_team_division(IdTeamDivision) { return { type: 'FOLLOW/TEAM', IdTeamDivision } }
export function follow_team(IdTeam) { return { type: 'FOLLOW/TEAM', IdTeam } }
export function fetch_match_collaborators(IdMatch) { return { type: 'MATCH/COLLABORATORS/FETCH', IdMatch } }
export function update_follow_level(IdUserFollow, IdFollowLevel) { return { type: 'FOLLOW_LEVEL/SET', IdFollowLevel, IdUserFollow } }

export function unfollow_team(IdTeamDivision) { return { type: 'UNFOLLOW/TEAM', IdTeamDivision } }
export function unfollow_match(IdMatch) { return { type: 'UNFOLLOW/MATCH', IdMatch } }

export function fetch_folders() { return { type: 'FOLDERS/FETCH' } }
export function create_folder(folder) { return { type: 'FOLDER/SAVE', folder } }
export function delete_folder(IdFolder) { return { type: 'FOLDER/DELETE', IdFolder } }
export function update_match_collaborator_rights(Blocked, IdMatch) { return { type: 'MATCH/COLLABORATOR/RIGHTS/SET', Blocked, IdMatch } }

// Notes saga
export function clear_notes() { return { type: 'NOTES/CLEAR' } }
export function fetch_notes() { return { type: 'NOTES/FETCH' } }
export function fetch_attributes() { return { type: 'ATTRIBUTES/FETCH' } }
export function fetch_evaluations() { return { type: 'EVALUATIONS/FETCH' } }
export function fetch_reviews() { return { type: 'REVIEWS/FETCH' } }
export function fetch_player_matches() { return { type: 'PLAYER_MATCHES/FETCH' } }
export function fetch_team_matches() { return { type: 'TEAM_MATCHES/FETCH' } }

export function move_match_to_folder(IdMatch, IdFolder, ReportType) { return { type: 'MATCH/FOLDER/MOVE', IdMatch, IdFolder, ReportType } }

export function fetch_family_subscriptions() { return { type: 'FAMILY/SUBSCRIPTIONS/FETCH' } }
export function fetch_all_plans() { return { type: 'PLANS/FETCH' } }

export function fetch_coach_profile(idUser) { return { type: 'COACH_PROFILE/FETCH', idUser } }
export function set_coach_profile(cp) { return { type: 'COACH_PROFILE/UPDATE', ...cp } }



/* FOLLOWING SEASONS */
export function fetchFollowSeasons() { return { type: 'FOLLOW/SEASONS/FETCH' } }
export function followSeason(season) { return { type: 'FOLLOW/SEASON', ...season } }
export function unfollowSeason(IdMatch) { return { type: 'UNFOLLOW/SEASON', IdMatch } }
