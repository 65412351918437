import React from 'react';
import { render } from 'react-dom';
import { Router, Route, browserHistory, IndexRoute } from 'react-router';
import { Provider } from 'react-redux';
import { syncHistoryWithStore } from 'react-router-redux';

import store from './store';

// CSS
import './css/fonts.css';
import './css/buttons.css';
import './css/colors.css';
import './css/helpers.css';
import './css/layout.css';
//import 'bootstrap/dist/css/bootstrap.css';
import './css/animations.css';
import './css/responsiveness.css';
import 'react-select/dist/react-select.css';
import "animate.css/animate.min.css";
import 'react-datepicker/dist/react-datepicker.css';

// Apps
import App_SQWAD from './apps/App.SQWAD';

// Common
import HomeNew from './components/home/index.js';
import Network from './components/network';
import Login from './components/home/login.js';
import Folder from './components/network/folder';
import TeamFolder from './components/network/team_folder';
import Team from './components/network/team';
import CustomTeam from './components/network/custom_team';
import Scouting from './components/scouting';
import Prospect from './components/network/prospect';
import CustomPlayer from './components/network/custom_player';

import ProspectHighlights from './components/network/prospect_highlights';
import ProspectEvaluations from './components/network/prospect_evaluations';

import Lead from './components/network/lead';
import LeadHighlights from './components/network/lead_highlights';

import CustomPlayerEvaluations from './components/network/custom_player_evaluations';
import CreateAccount from './components/create_account';


import fakeAuth from './fakeAuth';
import Recruits from './components/recruiting';
import Notes from './components/scouting/notes';
import AttributesMain from './components/scouting/attributes';
import EvaluationsMain from './components/scouting/evaluations';
import ReviewsMain from './components/scouting/reviews';
import PlayerMatches from './components/scouting/player_matches';
import TeamMatches from './components/scouting/team_matches';
import NotesFolder from './components/network/notes_folder';
import EvaluationsFolder from './components/network/evaluations_folder';
import AttributesFolder from './components/network/attributes_folder';
import ReviewsFolder from './components/network/reviews_folder';
import PlayerMatchFolder from './components/network/pm_folder';
import TeamMatchFolder from './components/network/tm_folder';
import PSFA from './components/psfa';
import LeadEvaluations from './components/network/lead_evaluations';
import Subscriptions from './components/subscriptions';
import Family from './components/family';
import FamilyMember from './components/family/member';
import FamilyContact from './components/family/contact';
import FamilyInfo from './components/family/info';
import ScoutingEvents from './components/scouting_events/index.js';
import Season from './components/network/season.js';

const history = syncHistoryWithStore(browserHistory, store)

function requireAuth(nextState, replace) {
  if (!fakeAuth.isAuthenticated()) {
    replace({
      pathname: '/login',
      state: { nextPathname: nextState.location.pathname }
    })
    return;
  }
}

function requireAdmin(nextState, replace) {
  if (!fakeAuth.isAuthenticated() || !fakeAuth.isAdmin()) {
    replace({
      pathname: '/login',
      state: { nextPathname: nextState.location.pathname }
    })
  }
}

class Root extends React.Component {
  render() {
    return (
      <Provider store={store}>
        { /* SITEMAP? */}
        <Router history={history}>

          {/* Freddy */}
          <Route path="/" component={App_SQWAD} onEnter={requireAuth}>
            <IndexRoute component={HomeNew} />

            <Route path="/dashboard" component={HomeNew} />
            <Route path="/network" component={Network} />
            <Route path="/network/folder" component={Folder} />
            <Route path="/network/folder/:id" component={Folder} />

            <Route path="/network/team_folder/:id" component={TeamFolder} />
            <Route path="/notes_folder/:id" component={NotesFolder} />
            <Route path="/evaluations_folder/:id" component={EvaluationsFolder} />
            <Route path="/attributes_folder/:id" component={AttributesFolder} />
            <Route path="/reviews_folder/:id" component={ReviewsFolder} />
            <Route path="/pm_folder/:id" component={PlayerMatchFolder} />
            <Route path="/tm_folder/:id" component={TeamMatchFolder} />

            <Route path="/family" component={Family} />
            <Route path="/family_member/:id" component={FamilyMember} />
            <Route path="/family_contact" component={FamilyContact} />
            <Route path="/family_info" component={FamilyInfo} />

            <Route path="/prospect/:id" component={Prospect} />
            <Route path="/prospect_highlights/:id" component={ProspectHighlights} />
            <Route path="/prospect_evaluations/:id" component={ProspectEvaluations} />
            <Route path="/custom_player/:id" component={CustomPlayer} />

            <Route path="/lead/:id" component={Lead} />
            <Route path="/lead_highlights/:id" component={LeadHighlights} />
            <Route path="/lead_evaluations/:id" component={LeadEvaluations} />

            <Route path="/custom_player_evaluations" component={CustomPlayerEvaluations} />
            <Route path="/custom_player_evaluations/:id" component={CustomPlayerEvaluations} />

            <Route path="/network/season/:id/:idSeason" component={Season} />
            <Route path="/network/team/:id" component={Team} />
            <Route path="/network/custom_team" component={CustomTeam} />
            <Route path="/network/custom_team/:id" component={CustomTeam} />

            <Route path="/recruits" component={Recruits} />

            <Route path="/scouting" component={Scouting} />
            <Route path="/scouting_notes" component={Notes} />
            <Route path="/scouting_attributes" component={AttributesMain} />
            <Route path="/scouting_evaluations" component={EvaluationsMain} />
            <Route path="/scouting_reviews" component={ReviewsMain} />

            <Route path="/scouting_events" component={ScoutingEvents} />

            <Route path="/scouting_player_matches" component={PlayerMatches} />
            <Route path="/player_match/:id" component={PlayerMatches} />

            <Route path="/scouting_team_matches" component={TeamMatches} />

            <Route path="/psfa" component={PSFA} />
            <Route path="/subscriptions" component={Subscriptions} />

          </Route>
          <Route path="/create_account" component={App_SQWAD}>
            <IndexRoute component={CreateAccount} />
          </Route>
          <Route path="/login" component={App_SQWAD}>
            <IndexRoute component={Login} />
          </Route>
          {/* /Freddy */}

          {/*<Route path="*" component={App_Public}>
            <IndexRoute component={Home} />
    </Route>*/}
        </Router>
      </Provider>
    )
  }
}

render(<Root />, document.querySelector('#root'));
