import React from 'react';
import Layout from '../layouts/claire';
import { Animated } from 'react-animated-css';
import Menu from '../home/partials/menu';
import { Link } from 'react-router';
import { Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import _ from 'lodash';
import LineLoader from '../partials/line_loader';
import moment from 'moment';
import MenuDesktop from './partials/menu_season_desktop';
import MenuMobile from './partials/menu_season_mobile';


class Season extends React.Component {
    state = {
        menu_toggled: false,
        mode: parseInt(localStorage.getItem('sqwadrc.mode') || 1, 10),
        page: 0,
        prospects: {
            list: [],
            total: 0
        },
        filters: localStorage.getItem('sqwadrc.filters') ? JSON.parse(localStorage.getItem('sqwadrc.filters')) : {},
        pager: false,
        search: localStorage.getItem('sqwadrc.search') || '',
        graduationYears: [moment().year(),
        moment().year() + 1,
        moment().year() + 2,
        moment().year() + 3,
        moment().year() + 4,
        moment().year() + 5,
        moment().year() + 6,
        moment().year() + 7,
        moment().year() + 8,
        moment().year() + 9,
        moment().year() + 10,
        ]
    }

    // Lifecycle
    componentWillMount() {

        this.props.scout_actions && this.props.scout_actions.fetchCountries();
        this.props.scout_actions && this.props.scout_actions.fetchCommunities();
        this.props.scout_actions && this.props.scout_actions.fetchFollowees();

    }
    componentDidMount() {
        const { id, idSeason } = this.props.params;
        this.props.scout_actions && this.props.scout_actions.fetch_match(id);
        this.props.scout_actions && this.props.scout_actions.fetchRecruitsBySeason({ IdGender: this.state.mode, Page: 0, SearchText: this.state.search, Filters: this.state.filters, IdSeason: idSeason });
    }
    componentWillReceiveProps = nextProps => {
        if (nextProps.prospects && !nextProps.loading) {
            this.setState({
                genders: _.chain(nextProps.prospects.list)
                    .map(u => u.IdGender)
                    .uniq()
                    .value(),
                pager: (this.state.search || _.compact(_.values(this.state.filters)).length > 0) ? false : true,
                prospects: {
                    list: _.chain([...this.state.prospects.list, ...(nextProps.prospects || { list: [] }).list || []])
                        .filter(u => u.IdGender === this.state.mode)
                        .uniqBy(u => u.IdUser)
                        .value(),
                    total: (nextProps.prospects || { total: 0 }).total
                }
            }, () => {
                this.setState({ nope: this.state.prospects.list.length ? false : true });
            });
        }
    }
    //

    toggleMode = ({ mode }) => {
        const { idSeason } = this.props.params;
        if (this.state.mode === mode) return;
        this.setState({ mode, prospects: { list: [], total: 0 }, page: 0, nope: false }, () => {
            localStorage.setItem('sqwadrc.mode', mode);
            this.props.scout_actions && this.props.scout_actions.fetchRecruitsBySeason({ IdGender: mode, Page: 50 * this.state.page, SearchText: this.state.search, Filters: this.state.filters, IdSeason: idSeason });
        });
    }
    loadMore = () => {
        const { idSeason } = this.props.params;
        this.setState({ page: this.state.page + 1 }, () => {
            this.props.scout_actions && this.props.scout_actions.fetchRecruitsBySeason({ IdGender: this.state.mode, Page: 50 * this.state.page, Filters: this.state.filters, IdSeason: idSeason });
        });
    }
    onSearch = (e) => {
        const { idSeason } = this.props.params;
        e && e.preventDefault && e.preventDefault();
        this.setState({ prospects: { list: [], total: 0 }, page: 0, nope: false }, () => {
            this.props.scout_actions.fetchRecruitsBySeason({ IdGender: this.state.mode, Page: 50 * this.state.page, SearchText: this.state.search, Filters: this.state.filters, IdSeason: idSeason });
            localStorage.setItem('sqwadrc.filters', JSON.stringify(this.state.filters));
            localStorage.setItem('sqwadrc.search', this.state.search);
        });
    }
    handleChangeSearch = event => { this.setState({ search: event.target.value }); }

    // Filters
    onSelectPosition1 = ({ Id, Name }) => {
        const { filters } = this.state;
        filters.Position1 = Name;
        this.setState({ filters }, this.onSearch);
    }
    onSelectPosition2 = ({ Id, Name }) => {
        const { filters } = this.state;
        filters.Position2 = Name;
        this.setState({ filters }, this.onSearch);
    }
    onSelectPosition3 = ({ Id, Name }) => {
        const { filters } = this.state;
        filters.Position3 = Name;
        this.setState({ filters }, this.onSearch);
    }
    onSelectNationalty = ({ IdCountry, CountryName }) => {
        const { filters } = this.state;
        filters.Nationality = CountryName;
        this.setState({ filters }, this.onSearch);
    }
    onSelectGraduationYear = (c) => {
        const { filters } = this.state;
        filters.GraduationYear = c;
        this.setState({ filters }, this.onSearch);
    }
    onSelectHLP = ({ IdCommunityLevelPlayed, LevelPlayed }) => {
        const { filters } = this.state;
        filters.IdCommunityLevelPlayed = IdCommunityLevelPlayed;
        filters.HLP = LevelPlayed;
        this.setState({ filters }, this.onSearch);
    }
    onSelectFoot = (f) => {
        const { filters } = this.state;
        filters.DominantFoot = f;
        this.setState({ filters }, this.onSearch);
    }

    renderCommon = () => {
        const { mode, prospects = {} } = this.state, { communities, match, coaches } = this.props, { list, total } = prospects;
        return <div className="w3-content pl-4 container pt-4">

            <div className="row">
                {match && <div className="d-flex flex-row w-100 mb-3">
                    <div className="w-100 py-2 tile card d-flex flex-row h-100">
                        <div className="px-2 ms-auto pointer" style={{ width: 50 }} ><i className="ms-auto white icon-more-vertical" /></div>
                        <div className="d-flex flex-column justify-content-center w-100 py-2">
                            <div className="align-self-center contain contains" style={{ width: 60, height: 60, background: `url(${match.LeagueImageUrl || '/images/logos/generic_crest.png'}) no-repeat center center` }}></div>
                            <span className="line1 font-10 black text-center mt-2">{match.SeasonName}</span>
                        </div>
                    </div>
                </div>}
                <div className="d-flex flex-row w-100">
                    <div className="tile  w-100 w-100" >
                        <form key="search-form">
                            <Input className="w-100 p-3 tile card" defaultValue={this.state.search} placeholder="Search" onChange={this.handleChangeSearch} />
                            <button type="submit" onClick={this.onSearch} className="w3-hide" />
                        </form>
                    </div>
                    <div className={`px-4 py-2 tile card d-flex flex-row ms-2 ${_.chain(this.state.filters).values().compact().value().length > 0 ? 'bg-warning' : ''}`} onClick={() => this.setState({ menu_toggled: true })}>
                        <i className="icon-filter black text-center font-24 align-self-center" />
                    </div>
                </div>

                <div className="d-flex flex-row syncopate text-center w-100 my-3">
                    {_.find(this.state.genders, g => g === 1) && <div className={`w-100 p-2 ${mode === 1 ? '' : 'bg-gray'}`} onClick={() => this.toggleMode({ mode: 1 })}>
                        <h4 className="p-0 m-0">MALES</h4>
                    </div>}
                    {_.find(this.state.genders, g => g === 2) && <div className={`w-100 p-2 ${mode === 2 ? '' : 'bg-gray'}`} onClick={() => this.toggleMode({ mode: 2 })}>
                        <h4 className="p-0 m-0">FEMALES</h4>
                    </div>}
                    <div className={`w-50 p-2 ${mode === 3 ? '' : 'bg-gray'}`} onClick={() => this.toggleMode({ mode: 3 })}>
                        <h4 className="p-0 m-0">COACHES</h4>
                    </div>
                </div>
            </div>

            {list && !this.state.nope && !this.props.loading && <h3 className="block my-4">({total}){`${this.state.search ? ' ' : ' HIGHLIGHTED '}`}RECRUITS</h3>}

            {/* RECRUITS */}
            {prospects && (mode === 1 || mode === 2) && <div className="row">
                {list && list.length > 0 && list.map((user, index) => {

                    let soccer = _.find(user.sports, s => s.IdCommunity === 1), position = '';

                    let nat_1 = _.find(this.props.countries || [], c => c.CountryName === user.Nationality1),
                        nat_2 = _.find(this.props.countries || [], c => c.CountryName === user.Nationality2);

                    let am_i_following_this_person = _.find(this.props.followees, f => f.IdUserFollow === user.IdUser);

                    let feet = (_.find(user.sports, s => s.DominantFoot) || { DominantFoot: '' }).DominantFoot, feet_icon = '';
                    switch (feet) {
                        case 'Left': feet_icon = 'Foot-Left'; break;
                        case 'Right': feet_icon = 'Foot-Right'; break;
                        default: break;
                    }

                    if (soccer) {
                        position = `${[soccer.Position1, soccer.Position2].join(' - ')}`;
                    }

                    return (
                        <Animated animationIn="fadeIn" className="col-md-6 p-1 w3-cell" key={index}>
                            <Link className="p-3 tile card h-100 d-flex flex-row" to={`${am_i_following_this_person ? `/prospect/${am_i_following_this_person.IdUserFollow}` : `/lead/${user.IdUser}`}`}>

                                <div className="d-flex flex-column">
                                    <i className={`fas fa-circle font-6 ${am_i_following_this_person ? (am_i_following_this_person.Blocked ? 'yellow' : 'green') : 'w3-hide'}`} />
                                </div>

                                <div className="align-self-center cover" style={{ width: 60, height: 60, borderRadius: 30, border: `1px solid gray`, background: `url(${user.UserImage}?full=75) no-repeat center center`, }} />

                                <div className="d-flex flex-column black ms-4 align-self-center w-75">
                                    <span className="line1 font-12">{user.NameFirst}</span>
                                    <span className="line1 font-12 toBold">{user.NameLast}</span>
                                    <span className="line1 font-10">{position}</span>
                                    <span className="black font-10 mt-2">{user.GraduationYear || '-'}</span>
                                </div>

                                <div className="ms-auto">
                                    <div className="d-flex flex-row mb-2">
                                        {nat_2 && <img src={nat_2.image} style={{ height: 15 }} className="me-1" />}
                                        {nat_1 && <img src={nat_1.image} style={{ height: 15 }} />}
                                    </div>
                                    <div className="d-flex flex-row mb-2">
                                        <i className={`ms-auto icon-x8-${feet_icon} black font-16 text-right`} />
                                    </div>
                                </div>
                            </Link>
                        </Animated>)
                })}
            </div>}

            {/* COACHES */}
            {mode === 3 && coaches && <div className="row">
                {coaches.map((user, index) => <Animated animationIn="fadeIn" className="col-md-6 p-1 w3-cell" key={index}>
                    <div className="p-3 tile card h-100 d-flex flex-row">

                        <div className="align-self-center cover" style={{ width: 60, height: 60, borderRadius: 30, border: `1px solid gray`, background: `url(${user.UserImageUrl}?full=75) no-repeat center center`, }} />

                        <div className="d-flex flex-column black ms-4 align-self-center w-75">
                            <span className="line1 font-12">{user.NameFirst}</span>
                            <span className="line1 font-14 toBold">{user.NameLast}</span>
                            <span className="line1 font-10 gray">{user.RoleOverride}</span>                            
                        </div>
                    </div>
                </Animated>)}
            </div>}

            {/* NO */}
            {this.state.nope && (mode === 1 || mode === 2) && <div className="d-flex w-100 flex-column justify-content-center">
                <img src="/images/icons/red_card.png" className="w-25 align-self-center mt-4" />
                <span className="black font-30 align-self-center text-center mx-4 px-4 line1 mt-4">Sorry, no recruits found<br />on your search</span>
            </div>}

            {(mode === 1 || mode === 2) &&<div className="row">
                {!this.props.loading && this.state.pager && <a onClick={this.loadMore} className="btn btn-link m-auto pointer link blue">{this.props.loading ? "LOADING...." : 'LOAD MORE'}</a>}
                {this.props.loading && this.state.pager && <span className="btn btn-link m-auto pointer link blue">{this.props.loading ? "LOADING...." : 'LOAD MORE'}</span>}
                <div style={{ height: 100 }} />
            </div>}
        </div>
    }
    render() {
        return (
            <Layout router={this.props.router} className="bg-white" nbs="network" title="SQWAD SCOUT | TEAM" form={this.props.form} form_actions={this.props.form_actions}>
                <section>
                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small" style={{ marginLeft: 200 }}>
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                        <MenuDesktop {...this.state} {...this.props} />
                    </div>
                    {/* MOBILE */}
                    <div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                        <MenuMobile {...this.state} {...this.props} />
                    </div>
                    <Menu hide={true} {...this.state} {...this.props} />
                </section>
            </Layout>
        )
    }
}

export default Season;
