import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import { humanize_user } from '../../helpers';
import request from 'superagent';

//fetchCommunities

export function* fetch_countries_saga() {
    yield* takeEvery("COUNTRIES/FETCH", function* (action) {
        try {
            const result = yield call(({ idMatch }) => {
                return request.get(`${config.apiEndpoint}/api/v4/geo/countries`)
                    .then(data => (JSON.parse(data.text)));
            }, action);
            if (result && result.success) {
                const { countries } = result;
                yield put({ type: 'COUNTRIES/SET', countries });
            } else {
                yield put({
                    type: 'COUNTRIES/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'COUNTRIES/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_communities_saga() {
    yield* takeEvery("COMMUNITIES/FETCH", function* (action) {
        try {
            const result = yield call(({ idMatch }) => {
                return request.get(`${config.apiEndpoint}/api/v4/common/communities`)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { communities } = result;
                yield put({ type: 'COMMUNITIES/SET', communities });
            } else {
                yield put({
                    type: 'COMMUNITIES/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'COMMUNITIES/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_penalties_saga() {
    yield* takeEvery("PENALTIES/FETCH", function* (action) {
        try {
            const result = yield call(({ IdCommunity }) => {
                return request.get(`${config.apiEndpoint}/api/v4/common/penalties/${IdCommunity}`)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { penalties } = result;
                yield put({ type: 'PENALTIES/SET', penalties });
            } else {
                yield put({
                    type: 'PENALTIES/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'PENALTIES/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_scout_match_saga() {
    yield* takeEvery("SCOUT/MATCH/FETCH", function* (action) {
        try {
            const result = yield call(({ idMatch }) => {
                return request.get(`${config.apiEndpoint}/api/v6/scout/match/${idMatch}`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { match } = result;
                yield put({ type: 'SCOUT/MATCH/SET', match });
            } else {
                yield put({
                    type: 'SCOUT/MATCH/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'SCOUT/MATCH/FETCH_FAILED',
                result: e
            });
        }
    });
}

//FOLLOW_LEVELS/FETCH
export function* fetch_follow_levels_saga() {
    yield* takeEvery("FOLLOW_LEVELS/FETCH", function* (action) {
        try {
            const result = yield call(({ idMatch }) => {
                return request.get(`${config.apiEndpoint}/api/v5/follow/levels`)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { levels } = result;
                yield put({ type: 'FOLLOW_LEVELS/SET', levels });
            } else {
                yield put({
                    type: 'FOLLOW_LEVELS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'FOLLOW_LEVELS/FETCH_FAILED',
                result: e
            });
        }
    });
}

// Search at the SQWAD Network
export function* fetch_recruits_saga() {
    yield* takeEvery("SCOUT/RECRUITS/FETCH", function* (action) {

        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ IdGender, Page, SearchText, Filters }) => {
                return request.post(`${config.apiEndpoint}/api/v5/college/search2`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .send({ IdGender, Page, SearchText, Filters })
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { prospects, TotalRows } = result;
                yield put({ type: 'PROSPECTS/SET', prospects: { list: prospects, total: TotalRows } });
            } else {
                yield put({
                    type: 'SCOUT/PROSPECTS/FETCH_FAILED',
                    result
                });
            }

            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({
                type: 'SCOUT/PROSPECTS/FETCH_FAILED',
                result: e
            });
        }
    });
}

// Search at the SQWAD Network
export function* fetch_recruits_season_saga() {
    yield* takeEvery("SCOUT/RECRUITS/SEASON/FETCH", function* (action) {

        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ IdGender, Page, SearchText, Filters, IdSeason }) => {
                return request.post(`${config.apiEndpoint}/api/v5/college/search_by_season/${IdSeason}`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .send({ IdGender, Page, SearchText, Filters })
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { prospects, TotalRows, coaches } = result;
                yield put({ type: 'PROSPECTS/SET', prospects: { list: prospects, total: TotalRows } });
                yield put({ type: 'COACHES/SET', coaches });
            } else {
                yield put({
                    type: 'SCOUT/PROSPECTS/FETCH_FAILED',
                    result
                });
            }

            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({
                type: 'SCOUT/PROSPECTS/FETCH_FAILED',
                result: e
            });
        }
    });
}

// Search at the SQWAD Network
export function* fetch_followees_saga() {
    yield* takeEvery("FOLLOWEES/FETCH", function* (action) {

        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v6/follow/players`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { users, custom_players } = result;
                yield put({ type: 'FOLLOWEES/SET', users: [...users, ...custom_players] });
            } else {
                yield put({
                    type: 'FOLLOWEES/FETCH_FAILED',
                    result
                });
            }

            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({
                type: 'FOLLOWEES/FETCH_FAILED',
                result: e
            });
        }
    });
}

// Search the teams I follow
export function* fetch_teams_saga() {
    yield* takeEvery("FOLLOWEES/TEAMS/FETCH", function* (action) {

        try {
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v6/follow/teams`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { teams, custom_teams } = result;
                yield put({ type: 'TEAMS/SET', teams: [...teams, ...custom_teams] });
            } else {
                yield put({
                    type: 'FOLLOWEES/TEAMS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'FOLLOWEES/TEAMS/FETCH_FAILED',
                result: e
            });
        }
    });
}

// Fetch a team and its roster
export function* fetch_team_saga() {
    yield* takeEvery("TEAM/FETCH", function* (action) {
        try {
            const result = yield call(({ idTeam }) => {
                return request.get(`${config.apiEndpoint}/api/external/teams/roster/${idTeam}`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { roster, team } = result;
                yield put({ type: 'TEAM/SET', team });
                yield put({ type: 'ROSTER/SET', roster });
            } else {
                yield put({
                    type: 'TEAM/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'TEAM/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_user_saga() {
    yield* takeEvery("USER/FETCH", function* (action) {

        try {
            const result = yield call(({ idUser }) => {
                return request.get(`${config.apiEndpoint}/api/v6/college/profile/${idUser}`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { profile } = result;
                humanize_user(profile);
                yield put({ type: 'USER/SET', user: profile });
            } else {
                yield put({
                    type: 'USER/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'USER/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_topics_saga() {
    yield* takeEvery("TOPICS/FETCH", function* (action) {

        try {
            const result = yield call(({ idCommunity }) => {
                return request.get(`${config.apiEndpoint}/api/v5/evaluations/topics/${idCommunity}`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { topics } = result;
                yield put({ type: 'TOPICS/SET', topics });
            } else {
                yield put({
                    type: 'TOPICS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'TOPICS/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_user_evaluations_fetch() {
    yield* takeEvery("USER/EVALUATIONS/FETCH", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ idUser }) => {
                return request.get(`${config.apiEndpoint}/api/v6/scout/user/evaluations/${idUser}`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { evaluations, topics } = result;
                yield put({ type: 'EVALUATIONS/SET', evaluations });
                yield put({ type: 'TOPICS/SET', topics });
            } else {
                yield put({
                    type: 'CUSTOM_PLAYER/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({
                type: 'CUSTOM_PLAYER/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_custom_player_saga() {
    yield* takeEvery("CUSTOM_PLAYER/FETCH", function* (action) {
        try {
            const result = yield call(({ idMatch }) => {
                return request.get(`${config.apiEndpoint}/api/v6/scout/match/${idMatch}`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { match } = result;
                yield put({ type: 'USER/SET', user: match });
            } else {
                yield put({
                    type: 'CUSTOM_PLAYER/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'CUSTOM_PLAYER/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_custom_player_evaluations_fetch() {
    yield* takeEvery("CUSTOM_PLAYER/EVALUATIONS/FETCH", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ idMatch }) => {
                return request.get(`${config.apiEndpoint}/api/v6/scout/custom_player/evaluations/${idMatch}`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { evaluations, topics } = result;
                yield put({ type: 'EVALUATIONS/SET', evaluations });
                yield put({ type: 'TOPICS/SET', topics });
            } else {
                yield put({
                    type: 'CUSTOM_PLAYER/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({
                type: 'CUSTOM_PLAYER/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_folders_saga() {
    yield* takeEvery("FOLDERS/FETCH", function* (action) {

        try {
            const result = yield call(({ }) => {
                return request.get(`${config.apiEndpoint}/api/v5/folders`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { folders } = result;
                yield put({ type: 'FOLDERS/SET', folders });
            } else {
                yield put({
                    type: 'FOLDERS/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'FOLDERS/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_folder_saga() {
    yield* takeEvery("FOLDER/FETCH", function* (action) {

        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ id }) => {
                return request.get(`${config.apiEndpoint}/api/v5/folders/${id}`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { folder } = result;
                yield put({ type: 'FOLDER/SET', folder });
            } else {
                yield put({
                    type: 'FOLDER/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({
                type: 'FOLDER/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_generic_match_saga() {
    yield* takeEvery("GENERIC_MATCH/FETCH", function* (action) {

        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ IdMatch }) => {
                return request.get(`${config.apiEndpoint}/api/v6/scout/match/${IdMatch}`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { match } = result;
                yield put({ type: 'MATCH/SET', match });
            } else {
                yield put({
                    type: 'MATCH/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({
                type: 'FOLDER/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_match_collaborators_saga() {
    yield* takeEvery("MATCH/COLLABORATORS/FETCH", function* (action) {

        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ IdMatch }) => {
                return request.get(`${config.apiEndpoint}/api/v6/scout/collaborators/personal_team/${IdMatch}`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { collaborators } = result;
                yield put({ type: 'COLLABORATORS/SET', collaborators });
            } else {
                yield put({
                    type: 'MATCH/COLLABORATORS/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({
                type: 'MATCH/COLLABORATORS/FETCH_FAILED',
                result: e
            });
        }
    });
}

//-----------------------


export function* save_custom_player_review_saga() {
    yield* takeEvery("CUSTOM_PLAYER/REVIEW/SAVE", function* (action) {

        try {
            const result = yield call(({ review }) => {
                return request[review.IdMatch ? 'patch' : 'post'](`${config.apiEndpoint}/api/v6/scout/generic_match${review.IdMatch ? `/${review.IdMatch}` : ''}`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .send(review)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                if (action.review.IdMatchTarget) {
                    yield put({ type: 'CUSTOM_PLAYER/EVALUATIONS/FETCH', idMatch: action.review.IdMatchTarget });
                }
            } else {
                yield put({
                    type: 'REVIEW/SAVE/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'REVIEW/SAVE/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* save_generic_match_saga() {
    yield* takeEvery("GENERIC_MATCH/SAVE", function* (action) {

        try {
            const result = yield call(({ match }) => {
                return request[match.IdMatch ? 'patch' : 'post'](`${config.apiEndpoint}/api/v6/scout/generic_match${match.IdMatch ? `/${match.IdMatch}` : ''}`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .send(match)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                if (action.match && action.match.IdMatchTarget) {
                    yield put({ type: 'CUSTOM_PLAYER/EVALUATIONS/FETCH', idMatch: action.match.IdMatchTarget });
                } if (action.match && action.match.IdUserTarget) {
                    yield put({ type: 'USER/EVALUATIONS/FETCH', idUser: action.match.IdUserTarget });
                } else {
                    if (action.match.ReportType === 10) yield put({ type: 'NOTES/FETCH' });
                    if (action.match.ReportType === 1) yield put({ type: 'EVALUATIONS/FETCH' });
                    if (action.match.ReportType === 2) yield put({ type: 'PLAYER_MATCHES/FETCH' });
                    if (action.match.ReportType === 3) yield put({ type: 'TEAM_MATCHES/FETCH' });
                    if (action.match.ReportType === 11) yield put({ type: 'ATTRIBUTES/FETCH' });
                    if (action.match.ReportType === 12) yield put({ type: 'REVIEWS/FETCH' });
                }
            } else {
                yield put({
                    type: 'REVIEW/SAVE/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'REVIEW/SAVE/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* delete_match_saga() {
    yield* takeEvery("MATCH/DELETE", function* (action) {

        try {
            const result = yield call(({ IdMatch }) => {
                return request.delete(`${config.apiEndpoint}/api/v6/scout/generic_match/${IdMatch}`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            // Optimistic
            if (result && result.success) {
                // Optimistic
            } else {
                yield put({
                    type: 'MATCH/DELETE/FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'REVIEW/SAVE/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* create_folder_saga() {
    yield* takeEvery("FOLDER/SAVE", function* (action) {

        try {
            const result = yield call(({ folder }) => {
                return request[folder.IdFolder ? 'patch' : 'post'](`${config.apiEndpoint}/api/v5/folders${folder.IdFolder ? `/${folder.IdFolder}` : ''}`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .send(folder)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            // Optimistic
            if (result && result.success) {
                // Optimistic
                yield put({ type: 'FOLDERS/FETCH' });
            } else {
                yield put({
                    type: 'FOLDER/SAVE/FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'FOLDER/SAVE/_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_folders() {
    yield* takeEvery("FOLDERS/FETCH", function* (action) {

        try {
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v5/folders/scout/all`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            // Optimistic
            if (result && result.success) {
                // Optimistic
                const { folders } = result;
                yield put({ type: 'FOLDERS/SET', folders });
            } else {
                yield put({
                    type: 'FOLDER/SAVE/FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'FOLDER/SAVE/_FAILED',
                result: e
            });
        }
    });
}

export function* delete_folder_saga() {
    yield* takeEvery("FOLDER/DELETE", function* (action) {

        try {
            const result = yield call(({ IdFolder }) => {
                return request.delete(`${config.apiEndpoint}/api/v5/folders/${IdFolder}`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            // Optimistic
            if (result && result.success) {
                // Optimistic
                yield put({ type: 'FOLDERS/FETCH' });
            } else {
                yield put({
                    type: 'FOLDER/SAVE/FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'FOLDER/SAVE/_FAILED',
                result: e
            });
        }
    });
}

export function* follow_match_saga() {
    yield* takeEvery("FOLLOW/MATCH", function* (action) {

        try {
            const result = yield call(({ IdMatch }) => {
                return request.post(`${config.apiEndpoint}/api/v5/follow/personal_team`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .send({ IdMatch })
                    .then(data => (JSON.parse(data.text)));
            }, action);

            // Optimistic
            if (result && result.success) {
                // Optimistic
                yield put({ type: 'FOLLOWEES/TEAMS/FETCH' });
            } else {
                yield put({
                    type: 'FOLDER/SAVE/FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'FOLDER/SAVE/_FAILED',
                result: e
            });
        }
    });
}

export function* unfollow_match_saga() {
    yield* takeEvery("UNFOLLOW/MATCH", function* (action) {

        try {
            const result = yield call(({ IdMatch }) => {
                return request.delete(`${config.apiEndpoint}/api/v5/follow/match/${IdMatch}`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            // Optimistic
            if (result && result.success) {
                // Optimistic
                yield put({ type: 'FOLLOWEES/TEAMS/FETCH' });
            } else {
                yield put({
                    type: 'FOLDER/SAVE/FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'FOLDER/SAVE/_FAILED',
                result: e
            });
        }
    });
}

export function* follow_team_saga() {
    yield* takeEvery("FOLLOW/TEAM", function* (action) {

        try {
            const result = yield call(({ IdTeamDivision, IdTeam }) => {
                return request.post(`${config.apiEndpoint}/api/v5/follow/team`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .send({ IdTeamDivision, IdTeam })
                    .then(data => (JSON.parse(data.text)));
            }, action);

            // Optimistic
            if (result && result.success) {
                // Optimistic
                yield put({ type: 'FOLLOWEES/TEAMS/FETCH' });
            } else {
                yield put({
                    type: 'FOLDER/SAVE/FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'FOLDER/SAVE/_FAILED',
                result: e
            });
        }
    });
}

export function* unfollow_team_saga() {
    yield* takeEvery("UNFOLLOW/TEAM", function* (action) {

        try {
            const result = yield call(({ IdTeamDivision }) => {
                return request.delete(`${config.apiEndpoint}/api/v5/follow/team/${IdTeamDivision}`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            // Optimistic
            if (result && result.success) {
                // Optimistic
                yield put({ type: 'FOLLOWEES/TEAMS/FETCH' });
            } else {
                yield put({
                    type: 'FOLDER/SAVE/FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'FOLDER/SAVE/_FAILED',
                result: e
            });
        }
    });
}

export function* update_follow_level() {
    yield* takeEvery("FOLLOW_LEVEL/SET", function* (action) {

        try {
            const result = yield call(({ IdFollowLevel, IdUserFollow }) => {
                return request.post(`${config.apiEndpoint}/api/v5/follow/levels`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .send({ IdFollowLevel, IdUserFollow })
                    .then(data => (JSON.parse(data.text)));
            }, action);

            // Optimistic
            if (result && result.success) {
                // Optimistic
                yield put({ type: 'FOLLOWEES/FETCH' });
            } else {
                yield put({
                    type: 'FOLLOWEES/FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'FOLLOWEES/_FAILED',
                result: e
            });
        }
    });
}

/*
const d = Q.defer();
                http.sportslogic.api.call({
                    service: `api/v6/scout/player_profile/move_to_folder`,
                    method: 'PATCH',
                    mod: 'Scouts',
                    parameters: { IdMatch, IdFolder }
                }).then((r) => {
                    Ti.App.fireEvent('app:evaluation:created');
                    d.resolve(r);
                }
                    , error => d.reject(error)
                );
                return d.promise;*/

export function* move_match_to_folder_saga() {
    yield* takeEvery("MATCH/FOLDER/MOVE", function* (action) {

        try {
            const result = yield call(({ IdMatch, IdFolder, ReportType }) => {
                return request.patch(`${config.apiEndpoint}/api/v6/scout/player_profile/move_to_folder`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .send({ IdMatch, IdFolder })
                    .then(data => (JSON.parse(data.text)));
            }, action);

            // Optimistic
            if (result && result.success) {
                // Optimistic
                if (action.ReportType === 10) yield put({ type: 'NOTES/FETCH' });
                if (action.ReportType === 1) yield put({ type: 'EVALUATIONS/FETCH' });
                if (action.ReportType === 2) yield put({ type: 'PLAYER_MATCHES/FETCH' });
                if (action.ReportType === 3) yield put({ type: 'TEAM_MATCHES/FETCH' });
                if (action.ReportType === 11) yield put({ type: 'ATTRIBUTES/FETCH' });
                if (action.ReportType === 12) yield put({ type: 'REVIEWS/FETCH' });
            } else {
                yield put({
                    type: 'FOLDER/SAVE/FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'FOLDER/SAVE/_FAILED',
                result: e
            });
        }
    });
}

export function* match_collaborator_rights_saga() {
    yield* takeEvery("MATCH/COLLABORATOR/RIGHTS/SET", function* (action) {

        try {
            const result = yield call(({ IdMatch, Blocked }) => {
                return request.patch(`${config.apiEndpoint}/api/v5/follow/collaborators/personal_team/${IdMatch}`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .send({ Blocked })
                    .then(data => (JSON.parse(data.text)));
            }, action);

            // Optimistic
            if (result && result.success) {
                // Optimistic
                yield put({ type: 'FOLLOWEES/TEAMS/FETCH' });
            } else {
                yield put({
                    type: 'FOLDER/SAVE/FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'FOLDER/SAVE/_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_states() {
    yield* takeEvery("STATES/FETCH", function* (action) {

        try {
            const result = yield call(function (idCountry) {
                const url = [config.apiEndpoint, 'api/v4/geo/states', idCountry].join('/');

                return request.get(url)
                    .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
                    .then((data) => {
                        return (JSON.parse(data.text))
                    });
            }, action.idCountry);

            if (result && result.success) {
                yield put({
                    type: 'STATES/SET',
                    states: result.states
                });

            } else {
                yield put({
                    type: 'STATES/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'STATES/FETCH_FAILED',
                e
            });
        }
    });
}

/* FOLLOWING SEASONS */

export function* fetch_follow_seasons_saga() {
    yield* takeEvery("FOLLOW/SEASONS/FETCH", function* (action) {

        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v5/follow/seasons`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { seasons } = result;
                yield put({ type: 'FOLLOW/SEASONS/SET', seasons });
            } else {
                yield put({
                    type: 'FOLLOWEES/FETCH_FAILED',
                    result
                });
            }

            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({
                type: 'FOLLOWEES/FETCH_FAILED',
                result: e
            });
        }
    });
}
//FOLLOW/SEASON
export function* follow_season_saga() {
    yield* takeEvery("FOLLOW/SEASON", function* (action) {

        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ SeasonName, IdSeason, SeasonImage, LeagueImageUrl }) => {
                return request.post(`${config.apiEndpoint}/api/v5/follow/season`)
                    .send({ SeasonName, IdSeason, SeasonImage, LeagueImageUrl })
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {                
                yield put({ type: 'FOLLOW/SEASONS/FETCH' });
            } else {
                yield put({
                    type: 'FOLLOWEES/FETCH_FAILED',
                    result
                });
            }

            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({
                type: 'FOLLOWEES/FETCH_FAILED',
                result: e
            });
        }
    });
}

//UNFOLLOW/SEASON
export function* unfollow_season_saga() {
    yield* takeEvery("UNFOLLOW/SEASON", function* (action) {

        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(({ IdMatch }) => {                
                return request.delete(`${config.apiEndpoint}/api/v5/follow/season/${IdMatch}`)                    
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {                
                yield put({ type: 'FOLLOW/SEASONS/FETCH' });
            } else {
                yield put({
                    type: 'FOLLOWEES/FETCH_FAILED',
                    result
                });
            }

            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({
                type: 'FOLLOWEES/FETCH_FAILED',
                result: e
            });
        }
    });
}