
import { fork, } from 'redux-saga/effects';

// Sagas
import { getRoster, getRosterMaster, getPlayerProfile } from './roster';
import { getVideosByUser, getVideo, getUser } from './videos';
import { getEvaluation } from './eval';
import { sendLeadSaga } from './leads';
import {
  fetch_notes_saga, fetch_attributes_saga,
  fetch_evaluations_saga, fetch_questionaries,
  fetch_reviews_saga, fetch_player_matches,
  fetch_player_matches_players, fetch_team_matches,
  fetch_questionaries_2,
} from './notes';


import {
  follow_accept_saga, follow_deny_saga,
  follow_accept_photo_replacement_saga, follow_deny_photo_replacement_saga,
  reference_accept_saga, reference_deny_saga, follow_user_saga, unfollow_user_saga,
  fetch_season_disclaimer
} from './follow';


import {
  fetch_scout_match_saga, fetch_recruits_saga, fetch_recruits_season_saga, fetch_communities_saga,
  fetch_countries_saga, fetch_followees_saga, fetch_user_saga, fetch_folders_saga,
  fetch_folder_saga, fetch_teams_saga, fetch_team_saga,
  fetch_follow_levels_saga, fetch_custom_player_saga,
  fetch_custom_player_evaluations_fetch,
  save_custom_player_review_saga, delete_match_saga,
  fetch_generic_match_saga,
  save_generic_match_saga, create_folder_saga,
  follow_match_saga, follow_team_saga,
  delete_folder_saga, unfollow_team_saga,
  unfollow_match_saga, fetch_match_collaborators_saga,
  match_collaborator_rights_saga, fetch_user_evaluations_fetch,
  fetch_folders, move_match_to_folder_saga,
  fetch_topics_saga, update_follow_level,
  fetch_states, fetch_penalties_saga, fetch_follow_seasons_saga,
  follow_season_saga, unfollow_season_saga
} from './scout';


import {
  family_subscriptions, all_plans,
  fetch_coach_profile, update_coach_profile,
  family_members,
} from './membership'


import {
  getFamilyContactSaga, assignPhoneNumberSaga,
  addPhoneSaga, deletePhoneSaga, updatePhoneSaga,
  assignEmailSaga, addEmailSaga, updateEmailSaga, deleteEmailSaga,
  getFamilyInformation,
} from './family';

import { fetch_custom_pages, fetch_public_match_reports } from './custom_pages';


export default function* rootSaga() {
  yield [
    fork(getRoster),
    fork(getRosterMaster),
    fork(getPlayerProfile),
    fork(getVideosByUser),
    fork(getVideo),
    fork(getUser),
    fork(getEvaluation),
    fork(follow_accept_saga),
    fork(follow_deny_saga),
    fork(follow_accept_photo_replacement_saga),
    fork(follow_deny_photo_replacement_saga),
    fork(sendLeadSaga),
    fork(fetch_scout_match_saga),
    fork(fetch_communities_saga),
    fork(fetch_followees_saga),
    fork(fetch_countries_saga),
    fork(fetch_follow_levels_saga),
    fork(fetch_folder_saga),
    fork(fetch_user_saga),
    fork(fetch_teams_saga),
    fork(fetch_team_saga),
    fork(fetch_folders_saga),
    fork(fetch_recruits_saga),
    fork(fetch_recruits_season_saga),
    fork(reference_accept_saga),
    fork(reference_deny_saga),
    fork(follow_user_saga),
    fork(unfollow_user_saga),
    fork(fetch_season_disclaimer),



    fork(fetch_custom_player_saga),
    fork(fetch_custom_player_evaluations_fetch),
    fork(save_custom_player_review_saga),
    fork(delete_match_saga),
    fork(fetch_generic_match_saga),
    fork(save_generic_match_saga),
    fork(create_folder_saga),
    fork(follow_match_saga),
    fork(follow_team_saga),
    fork(delete_folder_saga),
    fork(unfollow_team_saga),
    fork(unfollow_match_saga),
    fork(fetch_match_collaborators_saga),
    fork(match_collaborator_rights_saga),
    fork(fetch_user_evaluations_fetch),
    fork(fetch_folders),
    fork(move_match_to_folder_saga),
    fork(fetch_topics_saga),
    fork(update_follow_level),
    fork(fetch_states),
    fork(fetch_penalties_saga),
    fork(fetch_follow_seasons_saga),
    fork(follow_season_saga),
    fork(unfollow_season_saga),



    fork(fetch_notes_saga),
    fork(fetch_attributes_saga),
    fork(fetch_evaluations_saga),
    fork(fetch_questionaries),
    fork(fetch_questionaries_2),
    fork(fetch_reviews_saga),
    fork(fetch_player_matches),
    fork(fetch_player_matches_players),
    fork(fetch_team_matches),



    fork(family_subscriptions),
    fork(all_plans),
    fork(fetch_coach_profile),
    fork(update_coach_profile),
    fork(family_members),


    fork(getFamilyContactSaga),
    fork(assignPhoneNumberSaga),
    fork(addPhoneSaga),
    fork(deletePhoneSaga),
    fork(updatePhoneSaga),
    fork(assignEmailSaga),
    fork(addEmailSaga),
    fork(updateEmailSaga),
    fork(deleteEmailSaga),
    fork(getFamilyInformation),

    // CUSTOM PAGES
    fork(fetch_custom_pages),
    fork(fetch_public_match_reports),
  ]
}