import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import _ from 'lodash';

class SeasonQR extends React.Component {
    state = {}
    componentWillMount = () => {
        const { team, season } = this.props;
    }

    follow = () => {
        const { season } = this.props;
        season.SeasonImage = season.LeagueImageUrl = season.LeagueImage;
        this.props.scout_actions && this.props.scout_actions.followSeason(season);

    }
    unfollow = () => {
        const { season, follow_seasons } = this.props;
        let match = _.find(follow_seasons, fs => parseInt(fs.IdSeason, 10) === parseInt(season.IdSeason, 10));
        if (match) this.props.scout_actions && this.props.scout_actions.unfollowSeason(match.IdMatch);
    }

    render() {
        const { followees, toggle, season, follow_seasons } = this.props, team = {}
        return <div>
            <div className="d-flex flex-row bg-white" style={{ borderBottom: `5px solid gray` }}>
                <i onClick={toggle} className="fas pointer fa-arrow-left font-18 black mx-4 align-self-center" />
                <div style={{ width: 150 }} className="d-flex flex-row justify-content-center p-4">
                    <div className="align-self-center contains contain" style={{ borderRadius: 5, width: 100, height: 100, background: `url(${season.LeagueImage || `/images/defaults/generic_crest.png`}) no-repeat center center white` }} />
                </div>
                <div className="d-flex flex-column justify-content-center">
                    <span className="blue font-18 montserrat toBold">{season.SeasonName}</span>
                </div>
                <Link className="montserrat ms-auto me-4 btn btn-link align-self-center black"><i className="far fa-eye" /> View</Link>
            </div>
            <div className="d-flex flex-row w3-content mx-auto">
                <div className="p-4  w-100 d-flex flex-column justify-content-center">
                    <div className="align-self-center bordered contains contain align-self-center" style={{ borderRadius: '50%', width: 200, height: 200, background: `url(${season.LeagueImage || `/images/defaults/generic_crest.png`}) no-repeat center center white` }} />
                    <span className="font-30 black montserrat align-self-center">{season.SeasonName}</span>
                    {/* FOLLOW BUTTONS */}
                    {_.find(follow_seasons, fs => parseInt(fs.IdSeason, 10) === parseInt(season.IdSeason, 10)) && <button onClick={this.unfollow} className="mt-4 mx-auto btn btn-danger" style={{ borderRadius: 25, width: 200 }}>UNFOLLOW</button>}
                    {!_.find(follow_seasons, fs => parseInt(fs.IdSeason, 10) === parseInt(season.IdSeason, 10)) && <button onClick={this.follow} className="mt-4 mx-auto btn btn-success" style={{ borderRadius: 25, width: 200 }}>FOLLOW</button>}
                </div>
                <div className="p-4  w-100 d-flex flex-column justify-content-center">
                    <div className="align-self-center bordered contains contain align-self-center" style={{ width: 300, height: 300, background: `url(${`https://api.sportslogic.net/api/v5/qrcodes/scout/season_brochure/${season.IdSeason}`}) no-repeat center center white` }} />
                    <span className="font-10 black montserrat align-self-center line1 mt-4">Scan this code with</span>
                    <span className="font-14 black sqwad-font align-self-center line1">SQWAD SCOUT APP</span>
                    <span className="font-10 black montserrat align-self-center line1">For digital transfer</span>
                </div>
            </div>
        </div>
    }
}

export default SeasonQR;